import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import {
  Section,
  Container,
  Subtitle,
  SectionTitle,
  ImageContainer,
  ProductsList,
} from "../global"

const FeaturedProducts = (props) => {
  const data = useStaticQuery(graphql`
    query {
      products: allProductsJson(
        sort: { fields: [weight], order: DESC }
        filter: { featured: { eq: true } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            title
            description
            shortDescription
            category
            iconImage {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <Section type={props.type} shape={props.shape}>
      <ProductsContainer>
        <SectionTitle>Check out our products</SectionTitle>
        <ProductsList products={data.products.edges}></ProductsList>
      </ProductsContainer>
    </Section>
  )
}

export default FeaturedProducts

const ProductsContainer = styled(Container)`
  /*max-width: 100%;*/
`
