import React from "react"
import Layout from "../components/common/layout/layout"
import HeroHeader from "../components/sections/hero-header"
import Challenges from "../components/sections/challenges"
import FeaturedProducts from "../components/sections/featuerd-products"
import ContactForm from "../components/contact-form"
import Introduction from "../components/sections/introduction"

import backShape2 from "../images/back-shape-2.svg"
import backShape1 from "../images/back-shape-1.svg"

import { Section, Container } from "../components/global"
const IndexPageOld = ({ location }) => (
  <Layout
    title="Microsoft 365 productivity and collaboration solutions "
    description=".action is a software development company that focuses on business processes automation and digital collaboration solutions for Microsoft 365"
    location={location}
  >
    <HeroHeader
      title="Unleash your full potential"
      subTitle="Microsoft 365 automation and productivity solutions to empower your team"
      backgroundImage="home-back.png"
      foregroundImage="home-front.png"
      middleImage="middle-shape.png"
    />
    <Introduction type="accent" />
    <Challenges
      style={{
        backgroundImage: `url(${backShape1})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
        backgroundSize: "cover",
      }}
    ></Challenges>
    <FeaturedProducts type="secondary" />
    <Section
      type="accent"
      style={{
        backgroundImage: `url(${backShape2})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        backgroundSize: "cover",
      }}
    >
      <Container narrow="true" shadow="true">
        <ContactForm title="Do you have any question? We are here to answer!" />
      </Container>
    </Section>
  </Layout>
)
const IndexPage = ({ location }) => (
  <Layout
    title="Microsoft 365 productivity and collaboration solutions "
    description=".action is a software development company that focuses on business processes automation and digital collaboration solutions for Microsoft 365"
    location={location}
  >
    <HeroHeader
      title="Under constraction now"
      subTitle="Our site will be launched very soon"
      backgroundImage="notfound-back.png"
      foregroundImage="notfound-front.png"
      middleImage="middle-shape.png"
    />
  </Layout>
)

export default IndexPage
