import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { Section, Container, SectionTitle, ImageContainer } from "../global"

const Challenges = (props) => {
  const data = useStaticQuery(graphql`
    query {
      challenges: allChallengesJson {
        edges {
          node {
            title
            description
            iconImage {
              publicURL
            }
          }
        }
      }
    }
  `)
  return (
    <Section type={props.type} style={props.style}>
      <Container>
        <SectionTitle>Challenges we solve</SectionTitle>
        <ChallengesList>
          {data.challenges.edges.map(({ node }) => (
            <ChallengeItem>
              <ChallengeImage>
                <ImageContainer>
                  <img src={node.iconImage.publicURL} alt={node.title} />
                </ImageContainer>
              </ChallengeImage>
              <ChallengeTitle>{node.title}</ChallengeTitle>
              <ChallengeDescription>{node.description}</ChallengeDescription>
            </ChallengeItem>
          ))}
        </ChallengesList>
      </Container>
    </Section>
  )
}

export default Challenges

const ChallengesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -17px;
`

const ChallengeItem = styled.div`
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    width: calc(33.33% - 34px);
  }
  margin: 10px 17px;

  &:hover figure {
    background-color: ${(props) => props.theme.color.accent};
  }
`

const ChallengeTitle = styled.h4`
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
`

const ChallengeImage = styled.div`
  max-width: 300px;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    figure {
      width: 170px;
      height: 170px;
      padding-bottom: 0;
    }
  }
`

const ChallengeDescription = styled.p`
  text-align: center;
  /*line-height: 2.2em;*/
`
